import React, { Component } from 'react';

class Error extends Component {
	render() {
    	return (
			<div align="center"><h1>Ajajaaaaj nu var det något som gick lite fel va? </h1>
				<div>
					<h1>Henke does not approve!</h1>
				</div>
			</div>
		);
	}
}

export default Error;
