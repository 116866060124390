import React, { Component, createRef } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./css/index.scss";

import ThreeScene from "./components/ThreeScene";
import Error from "./components/Error";

class App extends Component {
  render() {
    // let url = window.location.href.replace("http://", "").replace(".vlq.se", "").split("/");

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} component={Index} />
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    );
  }
}

//https://stackoverflow.com/questions/44188969/how-to-pass-the-match-when-using-render-in-route-component-from-react-router-v4
class Index extends Component {
  constructor() {
    super();

    this.audioNode = createRef();
    this.wrapperNode = createRef();
    this.canvasNode = createRef();

    this.state = {
      initialControls: true,
      loading: true,
      paused: true,
      displayControls: true,
      showMenu: false,
      mute: true,
      analyserNode: {},
    };

    this.imageload = this.imageload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.onClickMenuToggle = this.onClickMenuToggle.bind(this);
    this.updateContext = this.updateContext.bind(this);
    this.interact = this.interact.bind(this);
    this.handlePress = this.handlePress.bind(this);
  }

  handlePlay(e) {
    this.setState({ paused: false, showMenu: false });
  }

  handlePause(e) {
    this.setState({ paused: true, showMenu: true });
  }

  onClickMenuToggle(e) {
    this.setState({ showMenu: !this.state.showMenu });
  }

  handleUpload(e) {
    this.audioNode.current.src = URL.createObjectURL(e.target.files[0]);
    this.updateContext();
  }

  updateContext() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    let audioCtx = new AudioContext();
    const audioSourceNode = audioCtx.createMediaElementSource(
      this.audioNode.current
    );

    const analyserNode = audioCtx.createAnalyser();
    analyserNode.fftSize = 8192;

    audioSourceNode.connect(analyserNode);
    analyserNode.connect(audioCtx.destination);

    this.setState({ analyserNode });

    this.frequencies = new Float32Array(analyserNode.frequencyBinCount);
    analyserNode.getFloatFrequencyData(this.frequencies);
  }

  imageload(e) {
    e.target.style.display = "block";
    e.target.parentNode.childNodes[0].style.display = "none";
  }

  interact() {
    this.updateContext();
    this.setState({ mute: false, initialControls: false });
    this.audioNode.current.play();
    window.removeEventListener("touchstart", this.interact);
    window.removeEventListener("mousedown", this.interact);
  }

  handlePress() {
    if (this.audioNode.current.paused) {
      this.audioNode.current.play();
    } else {
      this.audioNode.current.pause();
    }
  }

  componentDidMount() {
    window.addEventListener("touchstart", this.interact);
    window.addEventListener("mousedown", this.interact);
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.audioNode.current.src);
    window.removeEventListener("touchstart", this.interact);
    window.removeEventListener("mousedown", this.interact);
  }

  render() {
    return (
      <main className="wrapper" ref={this.wrapperNode}>
        <div
          className="canvas"
          ref={this.canvasNode}
          onMouseDown={this.handlePress}
          onTouchStart={this.handlePress}
          draggable="true"
        >
          <ThreeScene
            analyserNode={this.state.analyserNode}
            paused={this.state.paused}
          />
        </div>
        {this.state.initialControls ? (
          <svg
            className="playButton"
            aria-hidden="true"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm74.77 217.3l-114.45 69.14a10.78 10.78 0 01-16.32-9.31V186.87a10.78 10.78 0 0116.32-9.31l114.45 69.14a10.89 10.89 0 010 18.6z" />
          </svg>
        ) : null}
        <div className="menuWrapper">
          <span onClick={this.onClickMenuToggle}>
            {this.state.showMenu ? "↓" : "↑"}
          </span>
          <div
            className="menu"
            style={{
              display: this.state.showMenu ? "flex" : "none",
            }}
          >
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload a sound file
            </label>
            <input id="file-upload" type="file" onChange={this.handleUpload} />
            <audio
              id="music"
              ref={this.audioNode}
              controls
              autoPlay
              muted={this.state.mute}
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              style={{
                visibility: this.state.displayControls ? "visible" : "hidden",
              }}
              src="/manuel_senfft_-_curriculum_vitae.mp3"
            ></audio>
          </div>
        </div>
      </main>
    );
  }
}

export default App;
//<Route exact path={settings.url + "verify/:string"} render={({match}) => <Verify username={this.state.username} match={match}></Verify>} />
